export const logoOriginal = [
	'690 134',
	//   '30',
	`<?xml version="1.0" encoding="UTF-8"?>
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 2070.4 337.5">
	  <defs>
		<style>
		  .cls-1 {
			fill: #fff;
			opacity: .2;
		  }
	
		  .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
			stroke-width: 0px;
		  }
	
		  .cls-1, .cls-2, .cls-5, .cls-8 {
			display: none;
		  }
	
		  .cls-2, .cls-7 {
			fill: #222123;
		  }
	
		  .cls-3 {
			fill: #7a33ff;
		  }
	
		  .cls-4, .cls-9, .cls-5 {
			fill: #181719;
		  }
	
		  .cls-9 {
			font-family: Apparat-Bold, Apparat;
			font-size: 299.54px;
			font-weight: 700;
		  }
	
		  .cls-6 {
			fill: #441d59;
		  }
		</style>
	  </defs>
	  <g class="cls-8">
		<text class="cls-9" transform="translate(15.78 271.56)"><tspan x="0" y="0">Domeseer</tspan></text>
	  </g>
	  <g class="cls-8">
		<g>
		  <path class="cls-5" d="M0,323.91V34.24h91.24c107.19,0,166.11,52.78,166.11,143.61s-62.6,146.06-166.11,146.06H0ZM57.69,273.18h29.46c74.46,0,110.88-29.87,110.88-95.74s-35.19-92.47-110.47-92.47h-29.87v188.21Z"/>
		  <path class="cls-4" d="M292.95,223.27c0-62.19,42.96-109.24,107.19-109.24s107.6,47.05,107.6,109.24-42.96,108.83-107.6,108.83-107.19-45.82-107.19-108.83ZM453.33,223.27c0-36.41-20.05-62.19-53.19-62.19s-52.78,25.78-52.78,62.19,19.64,61.78,52.78,61.78,53.19-25.37,53.19-61.78Z"/>
		  <path class="cls-4" d="M868.61,191.35v132.56h-54.42v-122.74c0-27.82-9.41-40.5-26.18-40.5s-33.55,11.05-49.51,26.59v136.65h-54.42v-122.74c0-27.82-9.41-40.5-26.18-40.5s-33.96,11.05-49.51,26.59v136.65h-54.42V122.21h48.28l2.46,29.87c20.05-22.5,44.6-38.05,72.83-38.05s47.05,14.32,55.64,40.91v-.82c20.46-23.73,45.41-40.1,74.87-40.1,37.23,0,60.55,27.41,60.55,77.33Z"/>
		  <path class="cls-4" d="M914.85,312.46l6.14-44.19h2.05c23.32,17.59,43.78,23.32,62.19,23.32,19.64,0,29.87-7.77,29.87-21.27,0-12.27-5.73-20.87-38.87-33.96-47.05-18.41-60.14-36.82-60.14-65.46,0-31.5,27.41-56.87,73.65-56.87,23.32,0,49.1,6.55,70.37,18.82l-6.14,43.78h-2.05c-21.68-16.36-42.96-22.5-58.92-22.5s-24.96,8.18-24.96,18.41,4.5,17.59,39.69,32.32c45.41,19.23,59.33,36.82,59.33,66.28,0,36.41-29.87,60.96-78.96,60.96-24.96,0-51.14-6.55-73.24-19.64Z"/>
		  <path class="cls-4" d="M1293.3,237.18h-138.29c2.05,32.32,22.5,51.96,52.37,51.96,21.68,0,47.05-6.96,66.69-24.14h2.05l5.73,43.78c-20.46,13.5-50.32,23.32-79.37,23.32-65.87,0-103.1-44.6-103.1-108.42s39.28-109.65,99.83-109.65,94.51,42.14,94.51,106.79c0,7.36,0,9.82-.41,16.37ZM1242.57,203.22c-1.64-31.1-17.18-49.1-41.73-49.1s-42.14,18.41-45.41,48.69l87.15.41Z"/>
		  <path class="cls-4" d="M1521.61,237.18h-138.29c2.05,32.32,22.5,51.96,52.37,51.96,21.68,0,47.05-6.96,66.69-24.14h2.05l5.73,43.78c-20.46,13.5-50.32,23.32-79.37,23.32-65.87,0-103.1-44.6-103.1-108.42s39.28-109.65,99.83-109.65,94.51,42.14,94.51,106.79c0,7.36,0,9.82-.41,16.37ZM1470.87,203.22c-1.64-31.1-17.18-49.1-41.73-49.1s-42.14,18.41-45.41,48.69l87.15.41Z"/>
		  <path class="cls-4" d="M1694.27,116.07l-3.68,54.82-2.86,1.23c-3.68-.82-6.96-1.23-11.87-1.23-18.82,0-36.82,9.41-52.37,30.69v122.33h-54.41V122.21h48.28l3.27,37.23c19.23-27.41,38.87-44.19,66.28-44.19,2.86,0,4.91,0,7.36.82Z"/>
		</g>
		<path class="cls-6" d="M127.74,332.1H0v-199.13L127.56,5.4l129.79,129.79v67.29c0,17.48-3.43,34.46-10.2,50.46-6.53,15.44-15.87,29.3-27.76,41.19-11.89,11.89-25.75,21.23-41.19,27.76-16,6.77-32.98,10.2-50.46,10.2ZM64.86,267.24h62.88c35.71,0,64.75-29.05,64.75-64.75v-40.43l-64.93-64.93-62.7,62.7v107.41Z"/>
	  </g>
	  <g>
		<path class="cls-3" d="M114.91,332.06H0V120.39L114.95,5.45l114.9,114.9v96.81h-61.97v-71.14l-52.92-52.92-52.98,52.98v124.02h52.93c29.19,0,52.93-23.75,52.93-52.93h61.97c0,30.69-11.95,59.55-33.65,81.25-21.7,21.7-50.56,33.66-81.25,33.66Z"/>
		<g>
		  <path class="cls-2" d="M-36,323.1V6.07H63.86c117.32,0,181.8,57.76,181.8,157.17s-68.51,159.86-181.8,159.86H-36ZM27.14,267.58h32.24c81.5,0,121.35-32.69,121.35-104.78S142.22,61.59,59.83,61.59H27.14v205.98Z"/>
		  <path class="cls-7" d="M284.61,212.95c0-68.06,47.02-119.56,117.32-119.56s117.77,51.49,117.77,119.56-47.02,119.11-117.77,119.11-117.32-50.15-117.32-119.11ZM460.15,212.95c0-39.85-21.94-68.06-58.21-68.06s-57.76,28.21-57.76,68.06,21.49,67.62,57.76,67.62,58.21-27.76,58.21-67.62Z"/>
		  <path class="cls-7" d="M914.65,178.02v145.08h-59.55v-134.34c0-30.45-10.3-44.33-28.66-44.33s-36.72,12.09-54.18,29.11v149.56h-59.55v-134.34c0-30.45-10.3-44.33-28.66-44.33s-37.17,12.09-54.18,29.11v149.56h-59.56V102.34h52.84l2.69,32.69c21.94-24.63,48.81-41.64,79.71-41.64s51.49,15.67,60.9,44.78v-.89c22.39-25.97,49.7-43.88,81.94-43.88,40.75,0,66.27,30,66.27,84.63Z"/>
		  <path class="cls-7" d="M1177.95,228.17h-151.35c2.24,35.37,24.63,56.87,57.32,56.87,23.73,0,51.49-7.61,72.99-26.42h2.24l6.27,47.91c-22.39,14.78-55.08,25.52-86.87,25.52-72.09,0-112.84-48.81-112.84-118.66s42.99-120.01,109.26-120.01,103.44,46.12,103.44,116.87c0,8.06,0,10.75-.45,17.91ZM1122.42,191c-1.79-34.03-18.81-53.73-45.67-53.73s-46.12,20.15-49.7,53.29l95.38.45Z"/>
		  <path class="cls-7" d="M1217.36,310.56l6.72-48.36h2.24c25.52,19.25,47.91,25.52,68.06,25.52,21.49,0,32.69-8.51,32.69-23.28,0-13.43-6.27-22.84-42.54-37.17-51.5-20.15-65.82-40.3-65.82-71.65,0-34.48,30-62.24,80.6-62.24,25.52,0,53.73,7.16,77.02,20.6l-6.72,47.91h-2.24c-23.73-17.91-47.02-24.63-64.48-24.63s-27.31,8.96-27.31,20.15,4.93,19.25,43.43,35.38c49.7,21.05,64.93,40.3,64.93,72.54,0,39.85-32.69,66.72-86.42,66.72-27.31,0-55.97-7.16-80.15-21.49Z"/>
		  <path class="cls-7" d="M1631.56,228.17h-151.35c2.24,35.37,24.63,56.87,57.32,56.87,23.73,0,51.5-7.61,72.99-26.42h2.24l6.27,47.91c-22.39,14.78-55.08,25.52-86.87,25.52-72.09,0-112.84-48.81-112.84-118.66s42.99-120.01,109.26-120.01,103.44,46.12,103.44,116.87c0,8.06,0,10.75-.45,17.91ZM1576.03,191c-1.79-34.03-18.81-53.73-45.67-53.73s-46.12,20.15-49.7,53.29l95.38.45Z"/>
		  <path class="cls-7" d="M1881.43,228.17h-151.35c2.24,35.37,24.63,56.87,57.32,56.87,23.73,0,51.5-7.61,72.99-26.42h2.24l6.27,47.91c-22.39,14.78-55.08,25.52-86.87,25.52-72.09,0-112.84-48.81-112.84-118.66s42.99-120.01,109.26-120.01,103.44,46.12,103.44,116.87c0,8.06,0,10.75-.45,17.91ZM1825.9,191c-1.79-34.03-18.81-53.73-45.67-53.73s-46.12,20.15-49.7,53.29l95.38.45Z"/>
		  <path class="cls-7" d="M2070.4,95.63l-4.03,60-3.13,1.34c-4.03-.89-7.61-1.34-12.99-1.34-20.6,0-40.3,10.3-57.32,33.58v133.89h-59.55V102.34h52.84l3.58,40.75c21.05-30,42.54-48.36,72.54-48.36,3.13,0,5.37,0,8.06.9Z"/>
		</g>
	  </g>
	  <rect class="cls-1" width="2070.4" height="208.24"/>
	</svg>`,
]
