import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { CCol, CRow, CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { Amplify, Auth } from 'aws-amplify'
import { Authenticator, useAuthenticator, SelectField, ThemeProvider } from '@aws-amplify/ui-react'
import { Button, Text, TextField, Heading, useTheme, View, Image, Radio, RadioGroupField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'

import { v4 as uuidv4 } from 'uuid'

import CIcon from '@coreui/icons-react'
import { logoOriginal } from './assets/brand/logo-original'
import { Provider } from 'react-redux'

//Amplify.configure(awsconfig);
// Amplify.configure({
//   aws_cognito_region: 'us-east-1', // (required) - Region where Amazon Cognito project was created
//   aws_user_pools_id: 'us-east-1_BurVfirnF', // (optional) -  Amazon Cognito User Pool ID
//   aws_user_pools_web_client_id: '37khhiv3vabsupm7kp14ju0lnd', // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
//   UsernameAttributes: 'email',
//   // aws_cognito_identity_pool_id:
//   //   'us-east-1:f602c14b-0fde-409c-9a7e-0baccbfd87d0', // (optional) - Amazon Cognito Identity Pool ID
//   aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
// })

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  UsernameAttributes: process.env.REACT_APP_USERNAMEATTRIBUTES,

  // aws_cognito_identity_pool_id:
  //   'us-east-1:f602c14b-0fde-409c-9a7e-0baccbfd87d0', // (optional) - Amazon Cognito Identity Pool ID
  aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in

  oauth: {
		domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: process.env.REACT_APP_APP_URL,
    redirectSignOut: process.env.REACT_APP_APP_URL,
    responseType: 'code',
    // Add the identity provider for Google
    identityProvider: 'Google',
  }
})

const Uuid = uuidv4();
const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <CIcon customClassName="sidebar-brand-full" icon={logoOriginal} height={32} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy;{new Date().getFullYear()} All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading className='text-center'
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          <h4>Sign in to continue</h4>
        </Heading>
      );
    },

    Footer() {
      const { toResetPassword } = useAuthenticator();
      // const handleGoogleLogin = async () => {
      //   try {
      //     await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
      //   } catch (error) {
      //       console.error('Error signing in with Google', error)
      //   }
      // }

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            className='primary'
          >
            Reset Password
          </Button>
          {/* <Button
        onClick={handleGoogleLogin}
        size="small"
        variation="default"
        className='google-button'
      >
        Login with Google
      </Button> */}
        </View>
      );
    },
  },

  SignUp: {
    FormFields() {
      return (
        <>
          <TextField
            name='username'
            placeholder=""
            value={Uuid}
            label=""
            type="hidden"
            errorMessage="There is an error"
          />
          <TextField
            type='email'
            placeholder="Enter your email"
            name="email"
            label="Email"
            // type="hidden"
            errorMessage="Please provide a valid email"
          />
          <TextField
            type='password'
            placeholder="Enter your Password"
            name="password"
            label="Password"
          // type="hidden"
          />
          <TextField
            type='password'
            placeholder="Please confirm your Password"
            name="confirm_password"
            label="Confirm Password"
          // type="hidden"
          />
          <RadioGroupField
            label="User role"
            name="custom:dome_role"
          >
            <Radio value="prop_owner">Owner</Radio>
            <Radio value="prop_manager">Manager</Radio>
            {/* <Radio value="tenant">Tenant</Radio> */}
          </RadioGroupField>
        </>
      );
    },
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading className='text-center'
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          <h4>Create a new account</h4>
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <>
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        </>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>DomeSeer</Text>;
    },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    email: {
      label: 'Email',
      placeholder: 'Enter your email',
      isRequired: true,
      order: 1,
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your Password',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password',
      order: 3,
    },
    // 'custom:dome_role': {
    //   placeHolder: 'Enter your role: prop_owner or prop_manager',
    //   isRequired: true,
    //   label: 'Role',
    //   order: 4,
    //  },
  },
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

function App() {
  return (
    <>
      <Authenticator loginMechanisms={['username']} formFields={formFields} components={components} socialProviders={['google', 'facebook']}>
        <HashRouter>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Routes>
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </Authenticator>
    </>
  )
}

export default App