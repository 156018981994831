import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface state {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  theme: string
  appkey: string
  idtoken: string
  orgid: string
  uuid:string
  useremail:string
  userrole:string
  firstname:string
  lastname:string
  logout : boolean
  tab:number
  session: any
  idpConf: boolean
  mqttmsg:string
  mqtttopic:string
}

const initialState: state = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: 'default',
  appkey: '',
  idtoken: '',
  orgid: '',
  uuid:'',
  useremail:'',
  userrole:'',
  firstname:'',
  lastname:'',
  logout : false,
  tab: 1,
  session: null,
  idpConf: false,
  mqttmsg:'',
  mqtttopic:''
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setReset: (state, action: PayloadAction<string>) => {
      //state = initialState,
      state.theme = 'default'
      state.appkey = ''
      state.idtoken = ''
      state.orgid = ''
      state.useremail = ''
      state.userrole = ''
      state.firstname = ''
      state.lastname = ''
      state.logout = true
      state.tab = 1
      state.idpConf = false
      state.mqttmsg = ''
      state.mqtttopic = ''
    },
    setTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    setAppkey: (state, action: PayloadAction<string>) => {
      state.appkey = action.payload;
    },
    setIdtoken: (state, action: PayloadAction<string>) => {
      state.idtoken = action.payload;
    },
    setOrgId: (state, action: PayloadAction<string>) => {
      state.orgid = action.payload;
    },
    togglesidebarShow: (state) => {
      state.sidebarShow = !state.sidebarShow;
    },
    togglesidebarUnfoldable: (state) => {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    toggleasideShow: (state) => {
      state.asideShow = !state.asideShow;
    },
    setUuid: (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
    },
    setUseremail: (state, action: PayloadAction<string>) => {
      state.useremail = action.payload;
    },
    setUserrole: (state, action: PayloadAction<string>) => {
      state.userrole = action.payload;
    },
    setFirstname: (state, action: PayloadAction<string>) => {
      state.firstname = action.payload;
    },
    setLastname: (state, action: PayloadAction<string>) => {
      state.lastname = action.payload;
    },
    setLogout: (state, action: PayloadAction<boolean>) => {
      state.logout = action.payload;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setidpConf: (state, action: PayloadAction<boolean>) => {
      state.idpConf = action.payload;
    },
    setMQTTreduxmsg: (state, action: PayloadAction<string>) => {
      state.mqttmsg = action.payload;
    },
    setMQTTreduxtopic: (state, action: PayloadAction<string>) => {
      state.mqtttopic = action.payload;
    },
    clearSession: (state, action: PayloadAction<any>) => {
      state.session = null;
    },
  },
});

export const { 
  setReset,
  setTheme,
  setAppkey, 
  setIdtoken,
  setOrgId,
  togglesidebarShow, 
  togglesidebarUnfoldable, 
  toggleasideShow,
  setUuid,
  setUseremail,
  setUserrole,
  setFirstname,
  setLastname,
  setTab,
  setLogout,
  setidpConf,
  setMQTTreduxmsg,
  setMQTTreduxtopic,
  clearSession
} = appStateSlice.actions;

// Create the Redux store with the app state reducer
const store = configureStore({
  reducer: {
    appState: appStateSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store
